import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdSearch } from "react-icons/md";

const ResultsSearch = ({ onSearchChange }) => {
    const { t } = useTranslation();

    return (
        <div className='flex flex-col w-2/3 '>

            <div className='bg-container flex items-center rounded-xl border border-sbtn overflow-hidden focus-within:border-none focus-within:ring-1 focus-within:ring-btn'>


                <input
                    id="search-input"
                    type="text"
                    placeholder={t('snr_style_location_grind_snowType')}
                    onChange={e => onSearchChange(e.target.value)}
                    className="w-full bg-container p-4 outline-none"
                />
                <div className='text-text p-2 px-4 border-l'>
                    <MdSearch size={20} />
                </div>
            </div>
        </div>
    );
};

export default ResultsSearch;

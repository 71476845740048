import React, { useContext, useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { MdLogin } from "react-icons/md";
import { SiRundeck } from "react-icons/si";
import { IoLogoFirebase } from "react-icons/io5";
import { FaStripe } from "react-icons/fa";
import { UserPreferencesContext } from '../../context/UserPreferencesContext';
import bg from '../../assets/bg.jpg';

const HomePage = () => {
  const { user, checkingStatus } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { colormode } = useContext(UserPreferencesContext);

  const [imageLoaded, setImageLoaded] = useState(false);
  const [preferencesLoaded, setPreferencesLoaded] = useState(false); // State to track when preferences are loaded

  useEffect(() => {
    if (colormode) {
      // Preferences have loaded, now we can display the image
      setPreferencesLoaded(true);
    }
  }, [colormode]);

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Ski-Lab: Organize, Test, Analyze</title>
      </Helmet>

      <div className='relative flex flex-col items-center min-h-screen p-5 overflow-hidden'>

        {preferencesLoaded && (
          <>
            <div
              className={`absolute inset-0 bg-cover bg-top filter transition-all duration-1000 ease-in-out ${imageLoaded ? 'opacity-20 blur-sm scale-110' : 'opacity-0'} `}
              style={{ backgroundImage: `url(${bg})` }}
            />
            <img
              src={bg}
              alt="Background"
              className="hidden" // Hide the actual image
              onLoad={() => setImageLoaded(true)} // Set loaded state on image load
            />
          </>
        )}

        <div className='relative flex-grow text-center animate-fade animate-duration-500'>
          <div className='text-center mt-10 md:mt-20 mb-4'>
            <i className='text-lg'>{t('welcome_to')}</i>
            <h1 className='text-5xl font-semibold italic mb-4'>SKI-LAB</h1>
            <i className='text-sm'>{t('slogan')}</i>
          </div>

          {!checkingStatus && (
            !user ? (
              <div className='mt-32'>
                <button
                  className='flex items-center mx-auto justify-center bg-btn text-btntxt py-3 px-5 rounded-3xl hover:opacity-90 transition duration-300 mb-10'
                  onClick={() => handleNavigation('/signin')}
                >
                  {t('getStarted')}
                  <MdLogin size={18} className='ml-2' />
                </button>
              </div>
            ) : (
              <div className='mt-32'>
                <div className='text-xl mb-5'>
                  <p className='text-sm'>{t('hello')}</p>
                  <p>{user.displayName}</p>
                </div>
                
                <button
                  className='flex mx-auto items-center justify-center bg-btn text-btntxt py-3 px-5 rounded-3xl hover:opacity-90 transition duration-300'
                  onClick={() => handleNavigation('/skis')}
                >
                  {t('start_test')}
                  <SiRundeck size={18} className='ml-2' />
                </button>
              </div>
            )
          )}
        </div>

        <footer className='flex items-center space-x-2 relative text-btn text-xs md:self-end  mt-auto mb-12 md:mb-0'>
          <p className='flex px-2 items-center border-r border-btn'>Secured by Firebase <IoLogoFirebase className='mr-1' /> & <FaStripe size={25} className='ml-1'/></p>
          <i>&copy; mySkiPark 2024</i>

        </footer>
      </div>
    </HelmetProvider>
  );
};

export default HomePage;

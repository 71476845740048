// SkiDetail.jsx
import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../../context/AuthContext';
import { getSkiTests } from '../../../firebase/firestoreFunctions';
import CombinedConditionsHeatmap from './CombinedConditionsHeatmap/CombinedConditionsHeatmap';
import PerformanceChart from './PerformanceChart/PerformanceChart';
import SelectYear from './SelectYear/SelectYear';
import { MdDelete, MdEdit, MdArchive, MdUnarchive } from "react-icons/md"; // Import MdNewReleases


const SkiDetail = ({ ski, onEdit, onDelete, onArchive, onUnarchive }) => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const [chartData, setChartData] = useState([]);
    const [selectedYear, setSelectedYear] = useState('');
    const [containerWidth, setContainerWidth] = useState('100%');
    const chartContainerRef = useRef(null);
    const [testResultsCache, setTestResultsCache] = useState({});
    const [selectedSnowType, setSelectedSnowType] = useState('');
    const [selectedTemperature, setSelectedTemperature] = useState('');

    // Define all possible snow types using useMemo
    const allSnowTypes = [
        'fresh',
        'fine_grained',
        'coarse_grained',
        'transformed',
        'sleet',
        'wet',
        'artificial',
    ];

    // Updated getPointsForRank to be rank-based with relative weighting
    const getPointsForRank = useCallback((rank, total) => {
        const maxPoints = 8;
        const minPoints = 1;

        if (total === 1) {
            return maxPoints; // Only one participant
        }

        if (total < 1) {
            console.warn('Total participants must be at least 1.');
            return 0;
        }

        // Calculate points based on rank and total participants
        const points = minPoints + ((total - rank) / (total - 1)) * (maxPoints - minPoints);
        return parseFloat(points.toFixed(2));
    }, []);

    // Function to compute performance category based on average score
    const getPerformanceCategory = useCallback((avgScore) => {

        if (avgScore >= 7) return 'great';
        if (avgScore >= 5) return 'good';
        if (avgScore >= 3) return 'average';
        if (avgScore >= 1.5) return 'bad';
        return 'very_bad';
    }, []);


    // Function to categorize temperature
    const categorizeTemperature = useCallback((temp) => {
        return temp; // Direct mapping since we're using individual degrees
    }, []);

    // Handle year selection change
    const handleYearChange = useCallback((event) => {
        setSelectedYear(event.target.value);
    }, []);

    // Custom Tooltip Component
    const CustomTooltip = useCallback(({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;
            return (
                <div className="custom-tooltip bg-background p-5 border border-sbtn shadow-xl rounded-3xl">
                    <p className='font-semibold text-lg mb-2'>{`${data.rank}/${data.total}`}</p>
                    <p className='text-sm'>{`${t('temperature')}: ${data.temp}°C`}</p>
                    <p className='text-sm'>{`${t('location')}: ${data.location}`}</p>
                    <p className="text-sm">{`${t('snow_type')}: ${t(data.snowType)}`}</p>
                    <p className="text-sm">{`${t('test_date')}: ${new Date(label).toLocaleDateString()}`}</p>
                </div>
            );
        }

        return null;
    }, [t]);

    // Updated dynamicTemperatureList to include all unique temperatures from chartData
    const dynamicTemperatureList = useMemo(() => {
        const temps = [...new Set(chartData.map(data => data.temp))].sort((a, b) => a - b);
        return temps;
    }, [chartData]);

    // Memoized calculation for combined performance
    // Enhanced Weighted Average Calculation
    const combinedPerformanceData = useMemo(() => {
        const performanceMap = {};

        // Initialize all combinations to 'Unknown'
        dynamicTemperatureList.forEach(temp => {
            allSnowTypes.forEach(snowType => {
                const key = `${temp}___${snowType}`;
                performanceMap[key] = {
                    temp,
                    snowType,
                    totalPoints: 0,
                    totalParticipants: 0,
                    count: 0,
                    totalRank: 0, // To calculate average rank
                    rankCount: 0,  // Number of ranks counted
                };
            });
        });

        // Update performanceMap with actual data
        chartData.forEach(data => {
            const temp = data.temp;
            const snowType = data.snowType.toLowerCase();
            const points = data.points;
            const rank = data.rank; // Ensure 'rank' exists in chartData
            const totalParticipants = data.total; // Assuming 'total' represents participants

            const key = `${temp}___${snowType}`;

            if (performanceMap[key]) {
                performanceMap[key].totalPoints += points * totalParticipants;
                performanceMap[key].totalParticipants += totalParticipants;
                performanceMap[key].count += 1;
                performanceMap[key].totalRank += rank;
                performanceMap[key].rankCount += 1;
            }
        });

        // Calculate weighted average scores and assign categories
        const averageScores = [];

        Object.values(performanceMap).forEach(item => {
            if (item.count > 0 && item.totalParticipants > 0) {
                const weightedAvgScore = parseFloat((item.totalPoints / item.totalParticipants).toFixed(2));
                const averageRank = parseFloat((item.totalRank / item.rankCount).toFixed(2));
                const category = getPerformanceCategory(weightedAvgScore);
                averageScores.push({
                    temperature: item.temp,
                    snowType: item.snowType,
                    averageScore: weightedAvgScore,
                    category: category,
                    count: item.count, // Number of tests for this condition
                    averageRank: averageRank, // Average rank for this condition
                });
            } else {
                averageScores.push({
                    temperature: item.temp,
                    snowType: item.snowType,
                    averageScore: null,
                    category: 'Unknown',
                    count: 0,
                    averageRank: null,
                });
            }
        });
        return averageScores;
    }, [chartData, dynamicTemperatureList, allSnowTypes, getPerformanceCategory]);

    // Filtered chart data based on selected filters
    const filteredChartData = useMemo(() => {
        return chartData.filter(data => {
            const date = new Date(data.testDate);
            const year = date.getFullYear().toString();
            const yearMatch = selectedYear ? year === selectedYear : true;
            const snowTypeMatch = selectedSnowType ? data.snowType === selectedSnowType : true;
            const temperatureMatch = selectedTemperature ? data.temp <= selectedTemperature : true;
            return yearMatch && snowTypeMatch && temperatureMatch;
        });
    }, [chartData, selectedYear, selectedSnowType, selectedTemperature]);

    // Filtered grind history based on selected year
    const filteredGrindHistory = useMemo(() => {
        return selectedYear
            ? ski.grindHistory.filter(data => {
                const grindDate = new Date(data.grindDate);
                const grindYear = grindDate.getFullYear().toString();
                return grindYear === selectedYear;
            })
            : ski.grindHistory;
    }, [selectedYear, ski.grindHistory]);

    // Combine all dates from chart data and grind history
    const allDates = useMemo(() => [
        ...chartData.map(data => data.testDate),
        ...filteredGrindHistory.map(data => new Date(data.grindDate).getTime())
    ], [chartData, filteredGrindHistory]);

    const minDate = useMemo(() => Math.min(...allDates), [allDates]);
    const maxDate = useMemo(() => Math.max(...allDates), [allDates]);

    // Determine filtered dates based on selected year
    const filteredDates = useMemo(() => {
        return selectedYear
            ? [
                ...filteredChartData.map(data => data.testDate),
                ...filteredGrindHistory.map(data => new Date(data.grindDate).getTime())
            ]
            : allDates;
    }, [selectedYear, filteredChartData, filteredGrindHistory, allDates]);

    const yearMinDate = useMemo(() => Math.min(...filteredDates), [filteredDates]);
    const yearMaxDate = useMemo(() => Math.max(...filteredDates), [filteredDates]);

    // Determine maximum rank for YAxis domain
    const maxRank = useMemo(() => {
        return chartData.length > 0 ? Math.max(...chartData.map(d => d.rank)) : 1;
    }, [chartData]);

    // Fetch Missing Tests
    useEffect(() => {
        const fetchTests = async () => {
            if (!ski.testIds || ski.testIds.length === 0) {
                return;
            }
            const uncachedTestIds = ski.testIds.filter(id => !testResultsCache[id]);
            if (uncachedTestIds.length === 0) {
                return;
            }

            try {
                const fetchedTests = await getSkiTests(user.uid, uncachedTestIds);
                setTestResultsCache(prev => {
                    const newCache = { ...prev };
                    fetchedTests.forEach(test => {
                        newCache[test.id] = test;
                    });
                    return newCache;
                });
            } catch (error) {
                console.error('Error fetching ski tests:', error);
            }
        };

        fetchTests();
    }, [ski.testIds, testResultsCache, user.uid]);

    // Process Chart Data when Tests are Available
    useEffect(() => {
        const processChartData = () => {
            if (!ski.testIds || ski.testIds.length === 0) {
                setChartData([]);
                return;
            }

            // Check if all tests are cached
            const allTestsAvailable = ski.testIds.every(id => testResultsCache[id]);
            if (!allTestsAvailable) {
                return;
            }

            const allTests = ski.testIds.map(id => testResultsCache[id]).filter(test => test);

            const allRankings = [];

            allTests.forEach(test => {
                // Sort rankings based on score (ascending)
                const sortedRankings = [...test.rankings].sort((a, b) => a.score - b.score);

                let currentRank = 1;
                let i = 0;

                while (i < sortedRankings.length) {
                    const currentScore = sortedRankings[i].score;
                    const tiedSkis = [sortedRankings[i]];
                    let j = i + 1;

                    // Group skis with the same score (tied ranks)
                    while (j < sortedRankings.length && sortedRankings[j].score === currentScore) {
                        tiedSkis.push(sortedRankings[j]);
                        j++;
                    }

                    const numberOfTiedSkis = tiedSkis.length;

                    // Assign the same points for tied ranks
                    const points = getPointsForRank(currentRank, sortedRankings.length);


                    // Assign points and rank to each tied ski
                    tiedSkis.forEach(ranking => {
                        allRankings.push({
                            ...ranking,
                            rank: currentRank, // Assign the starting rank
                            points: points, // Assign same points for tied ranks
                            numberOfSkiesInTest: sortedRankings.length,
                            testDate: new Date(test.timestamp).getTime(),
                            location: test.location,
                            temp: test.temperature,
                            snowType: test.snowType,
                        });
                    });

                    // Move to the next rank
                    currentRank += numberOfTiedSkis;
                    i = j;
                }
            });

            // Filter rankings for the current ski
            let currentSkiRankings = allRankings.filter(ranking => ranking.skiId === ski.id);
            currentSkiRankings.sort((a, b) => a.testDate - b.testDate);


            const formattedChartData = currentSkiRankings.map(ranking => ({
                testDate: ranking.testDate,
                rank: ranking.rank, // Include rank
                points: ranking.points, // Keep points for heatmap
                total: ranking.numberOfSkiesInTest,
                location: ranking.location,
                temp: ranking.temp,
                snowType: ranking.snowType,
            }));

            setChartData(formattedChartData);

            if (formattedChartData.length > 0) {
                const latestYear = new Date(Math.max(...formattedChartData.map(d => d.testDate))).getFullYear().toString();
                setSelectedYear(latestYear);
            } else {
                console.log('No rankings available for the current ski.');
            }
        };

        processChartData();
    }, [testResultsCache, ski.testIds, getPointsForRank, ski.id]);

    // Adjust container width based on selectedYear
    useEffect(() => {
        if (chartContainerRef.current) {
            if (selectedYear) {
                setContainerWidth('100%');
                chartContainerRef.current.scrollLeft = 0;
            } else {
                setContainerWidth('200%');
                setTimeout(() => {
                    if (chartContainerRef.current) {
                        chartContainerRef.current.scrollLeft = chartContainerRef.current.scrollWidth;
                    }
                }, 400);
            }
        }
    }, [chartData, selectedYear]);

    const availableYears = useMemo(() => {
        const yearsSet = new Set(
            chartData.map(data => new Date(data.testDate).getFullYear().toString())
        );
        return Array.from(yearsSet).sort();
    }, [chartData]);

    return (
        <div className="px-3 pb-4 animate-fade-down animate-duration-300">
            {/* Ski Information Section */}
            <div className='grid grid-cols-2 gap-2'>
                <div>
                    <label className='text-sm' htmlFor="brand">{t('brand')}</label>
                    <p className='font-semibold'>{ski.brand || "--"}</p>
                </div>

                <div>
                    <label className='text-sm' htmlFor="model">{t('model')}</label>
                    <p className='font-semibold'>{ski.model || "--"}</p>
                </div>
                <div>
                    <label className='text-sm' htmlFor="length">{t('length')}</label>
                    <p className='font-semibold'>{ski.length || "--"}</p>
                </div>
                <div>
                    <label className='text-sm' htmlFor="stiffness">{t('stiffness')}</label>
                    <p className='font-semibold'>{ski.stiffness || "--"}</p>
                </div>
                <div>
                    <label className='text-sm' htmlFor="base">Base</label>
                    <p className='font-semibold'>{ski.base || "--"}</p>
                </div>
                <div>
                    <label className='text-sm' htmlFor="construction">{t('construction')}</label>
                    <p className='font-semibold'>{ski.construction || "--"}</p>
                </div>
                <div>
                    <label className='text-sm' htmlFor="grind_date">{t('grind_date')}</label>
                    <p className='font-semibold'>{ski.grindDate ? new Date(ski.grindDate).toLocaleDateString() : "--"}</p>
                </div>
                <div>
                    <label className='text-sm' htmlFor="comment">{t('comment')}</label>
                    <p className='font-semibold'>{ski.comment || "--"}</p>
                </div>
            </div>

            {/* Performance Section */}
            <div>
                <div className="flex justify-between items-center my-5">
                    <h2 className="text-2xl font-semibold">{t('performance')}</h2>
                    {ski.testIds && ski.testIds.length > 0 && (
                        <SelectYear
                            selectedYear={selectedYear}
                            handleYearChange={handleYearChange}
                            availableYears={availableYears}
                        />
                    )}
                </div>

                {ski.testIds && ski.testIds.length > 0 ? (
                    <>
                        {/* Performance Chart */}
                        {!chartData.length ? (
                            <span>{t('test_to_see_performance')}</span>
                        ) : (
                            <PerformanceChart
                                data={filteredChartData}
                                filteredGrindHistory={filteredGrindHistory}
                                selectedYear={selectedYear}
                                yearMinDate={yearMinDate}
                                yearMaxDate={yearMaxDate}
                                minDate={minDate}
                                maxDate={maxDate}
                                maxRank={maxRank}
                                CustomTooltip={CustomTooltip}
                                containerWidth={containerWidth}
                            />
                        )}
                    </>
                ) : (
                    <i>{t('no_tests')}</i>
                )}
            </div>


            {/* Recommended Conditions Section */}
            <div>
                <h2 className='text-2xl my-4 font-semibold'>{t('recommended_conditions')}</h2>

                {ski.testIds && ski.testIds.length > 0 ? (
                    combinedPerformanceData && combinedPerformanceData.length > 0 ? (
                        <div className='flex flex-col'>
                            <CombinedConditionsHeatmap
                                temperatureList={dynamicTemperatureList}
                                allSnowTypes={allSnowTypes}
                                combinedPerformanceData={combinedPerformanceData}
                                containerWidth={containerWidth}
                            />
                        </div>
                    ) : (
                        <i>{t('no_tests')}</i>
                    )
                ) : (
                    <i>{t('no_tests')}</i>
                )}
            </div>

            {/* Delete and Archive Buttons */}
            <div className="flex border-t pt-4 w-fit text-center mx-auto space-x-10 mt-10">
                <div className='flex flex-col space-y-2 items-center justify-center'>
                    <button
                        onClick={onEdit}
                        className='shadow bg-btn text-btntxt font-semibold hover:opacity-90 rounded-3xl p-3 cursor-pointer'
                    >
                        <MdEdit />
                    </button>
                    <label className='text-sm' htmlFor="edit">{t('edit')}</label>

                </div>

                {ski.archived ?
                    // If ski is archived, show restore icon
                    <div className='flex flex-col space-y-2 items-center justify-center'>
                        <button
                            onClick={onUnarchive}
                            className='shadow text-white bg-gradient-to-r from-green-400 to-green-600 hover:opacity-90 rounded-3xl p-3 cursor-pointer'
                        >
                            <MdUnarchive />
                        </button>
                        <label className='text-sm' htmlFor="unarchive">{t('unarchive')}</label>
                    </div>
                    :
                    <div className='flex flex-col space-y-2 items-center justify-center'>
                        <button
                            className="shadow text-white bg-orange-500 hover:opacity-90 rounded-3xl p-3 cursor-pointer"
                            onClick={onArchive}
                        >
                            <MdArchive />
                        </button>
                        <label className='text-sm' htmlFor="archive">{t('archive')}</label>
                    </div>
                }
                <div className='flex flex-col space-y-2 items-center justify-center'>
                    <button
                        className="shadow text-white bg-delete hover:opacity-90 rounded-3xl p-3 cursor-pointer"
                        onClick={onDelete}
                    >
                        <MdDelete />
                    </button>
                    <label className='text-sm' htmlFor="delete">{t('delete')}</label>

                </div>

            </div>
        </div>
    );

};

export default SkiDetail;

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { addUserTournamentResults, updateSkiRanking } from '../../../firebase/firestoreFunctions';
import { calculateSkiRankings, mapRankingsToTournamentData } from '../../../helpers/helpers';
import ResultList from './ResultList/ResultList';
import Spinner from '../../../components/common/Spinner/Spinner';
import { useTranslation } from 'react-i18next';
import SaveTestInput from './SaveTestInput/SaveTestInput';


const TestSummaryPage = ({ skis, calculateRankings, goBack }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const rankings = calculateRankings();

  const [weather, setWeather] = useState(null);
  const [locationError, setLocationError] = useState(false);

  useEffect(() => {
    // Handle page unload to prevent data loss
    const handleBeforeUnload = (e) => {
      const message = 'Are you sure you want to leave? Any unsaved changes will be lost.';
      e.returnValue = message;
      return message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const skiRankings = calculateSkiRankings(rankings, skis);

  // Function to determine the initial style based on skis
  const determineInitialStyle = () => {
    const styles = [...new Set(skis.map((ski) => ski.style))];
    if (styles.length === 1) {
      return styles[0];
    } else if (styles.length > 1) {
      // Optionally, you can decide to default to a specific style or prompt the user
      // For this example, we'll default to 'classic'
      return 'classic';
    } else {
      // Default value if no styles are found
      return '';
    }
  };

  const [additionalData, setAdditionalData] = useState({
    location: '',
    style: determineInitialStyle(), // Set default style here
    temperature: '',
    snowTemperature: '',
    humidity: '',
    snowType: '',
    comment: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAdditionalData({ ...additionalData, [name]: value });
  };

  const handleSaveResults = async (e) => {
    e.preventDefault();
    if (!user) {
      console.log('User not found');
      return;
    }

    try {
      setLoading(true);

      // Store the returned testId from addUserTournamentResults
      const testId = await addUserTournamentResults(
        user.uid,
        mapRankingsToTournamentData(rankings),
        additionalData
      );

      // Pass the testId to updateAllSkiRankings
      await updateAllSkiRankings(user.uid, skiRankings, testId);
    } catch (error) {
      console.error('Error: ', error);
    } finally {
      setLoading(false);
      navigate('/results');
    }
  };

  const styleOptions = [
    { label: t('classic'), value: 'classic' },
    { label: t('skate'), value: 'skate' },
  ];

  const snowTypeOptions = [
    { label: t('fresh'), value: 'fresh' },
    { label: t('fine_grained'), value: 'fine_grained' },
    { label: t('coarse_grained'), value: 'coarse_grained' },
    { label: t('transformed'), value: 'transformed' },
    { label: t('wet'), value: 'wet' },
    { label: t('sleet'), value: 'sleet' },
    { label: t('artificial'), value: 'artificial' },
  ];

  useEffect(() => {
    const getPosition = () => {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
    };

    const fetchWeather = async (latitude, longitude) => {
      const apiKey = process.env.REACT_APP_OPENWEATHER_API_KEY;
      const url = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&units=metric&appid=${apiKey}`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setWeather(data);

        // Set the default values in additionalData
        setAdditionalData((prevData) => ({
          ...prevData,
          location: data.name || '',
          temperature: data.main.temp ? Math.round(data.main.temp).toString() : '',
        }));
      } catch (error) {
        console.error('Could not fetch weather data', error);
      }
    };

    const requestLocation = () => {
      setLocationError(false);
      getPosition()
        .then((position) => {
          fetchWeather(position.coords.latitude, position.coords.longitude);
        })
        .catch((err) => {
          console.error("Could not get the user's position", err);
          setLocationError(true);
        });
    };

    requestLocation();
  }, []);

  if (loading) return <Spinner />;

  return (
    <div className="py-4 px-2">
      <h3 className="text-3xl mb-5 font-semibold text-dominant">{t('summary')}</h3>
      <div className="space-y-5">
        <div className="">
          <ResultList rankings={rankings} />
        </div>
        <div>
          <form
            className="rounded-xl flex flex-col md:grid md:grid-cols-2 md:gap-2 text-black my-2"
            onSubmit={handleSaveResults}
          >
            <SaveTestInput
              type="text"
              name="location"
              placeholder={t('location')}
              onChange={handleInputChange}
              value={additionalData.location}
              required
            />
            <SaveTestInput
              type="select"
              name="style"
              placeholder={t('style')}
              onChange={handleInputChange}
              value={additionalData.style}
              required
              options={styleOptions}
            />
            <SaveTestInput
              type="number"
              name="temperature"
              placeholder={t('temperature')}
              value={additionalData.temperature}
              onChange={handleInputChange}
              required
            />
            <SaveTestInput
              type="select"
              name="snowType"
              placeholder={t('snow_type')}
              value={additionalData.snowType}
              onChange={handleInputChange}
              options={snowTypeOptions}
              required
            />
            <SaveTestInput
              type="number"
              name="snowTemperature"
              placeholder={t('snow_temperature')}
              value={additionalData.snowTemperature}
              onChange={handleInputChange}
            />
            <SaveTestInput
              type="number"
              name="humidity"
              placeholder={t('humidity')}
              value={additionalData.humidity}
              onChange={handleInputChange}
            />

            <div className="col-span-2">
              <SaveTestInput
                type="text"
                name="comment"
                placeholder={t('comment')}
                value={additionalData.comment}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-1 space-x-2">
              <button
                className="px-5 py-3 bg-btn text-btntxt my-2 rounded-3xl w-fit hover:opacity-90"
                type="submit"
              >
                {t('save')}
              </button>

              <button
                type="button"
                className="px-5 py-3 bg-sbtn text-text rounded-3xl w-fit"
                onClick={goBack}
              >
                {t('back')}
              </button>
            </div>
          </form>
          {locationError && (
            <div className="text-red-500">
              Please enable location services to autofill weather data.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Ensure you have this helper function defined
const updateAllSkiRankings = async (userId, skiRankings, testId) => {
  for (const ski of skiRankings) {
    await updateSkiRanking(userId, ski.id, testId);
  }
};

export default TestSummaryPage;

import React from 'react';
import { useTranslation } from 'react-i18next';


const DateSort = ({ onSortChange }) => {
  const { t } = useTranslation();
  const handleSortChange = (event) => {
    const { value } = event.target;
    onSortChange(value);
  };

  return (
    <div className='flex flex-col '>
      <label htmlFor="date-sort" className="text-sm font-semibold mb-1">{t('sort_by')}</label>
      <select id="date-sort" defaultValue="desc" onChange={handleSortChange} className="bg-sbtn text-btn-txt rounded p-2">
        <option value="asc">{t('oldest')}</option>
        <option value="desc">{t('newest')}</option>
      </select>
    </div>
  );
};

export default DateSort;

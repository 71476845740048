import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import useTournamentResults from '../../hooks/useTournamentResults';
import Spinner from '../../components/common/Spinner';
import DateSort from './DateSort/DateSort';
import { MdDelete, MdEdit } from "react-icons/md";
import { getSkiData } from '../../firebase/firestoreFunctions';
import { useTranslation } from 'react-i18next';
import ResultsSearch from './ResultSearch/ResultSearch';

const ResultsPage = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [resultsToShow, setResultsToShow] = useState([]);
    const [sortOrder, setSortOrder] = useState('desc');
    const { results, loading, error, deleteResult } = useTournamentResults();
    const { t } = useTranslation();
    const { user } = useAuth();
    const navigate = useNavigate();
    const [skiDetails, setSkiDetails] = useState({});

    useEffect(() => {
        const fetchSkiDetails = async (userId, skiId) => {
            try {
                const data = await getSkiData(userId, skiId);
                setSkiDetails(prevDetails => ({
                    ...prevDetails,
                    [skiId]: data
                }));
            } catch (error) {
                console.error("Error fetching ski details:", error);
            }
        };

        results.forEach(result => {
            result.rankings.forEach(ranking => {
                fetchSkiDetails(user.uid, ranking.skiId);
            });
        });
    }, [results, user.uid]);

    useEffect(() => {
        const filteredSortedResults = results
        .filter(result => {
            const styleEnglish = t(result.style, { lng: 'en' }).toLowerCase();
            const styleNorwegian = t(result.style, { lng: 'no' }).toLowerCase();

            const snowTypeEnglish = t(result.snowType, { lng: 'en' }).toLowerCase();
            const snowTypeNorwegian = t(result.snowType, { lng: 'no' }).toLowerCase();
            
            return (
                styleEnglish.includes(searchTerm.toLowerCase()) || 
                styleNorwegian.includes(searchTerm.toLowerCase()) ||
                snowTypeEnglish.includes(searchTerm.toLowerCase()) || 
                snowTypeNorwegian.includes(searchTerm.toLowerCase()) ||
                result.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
                result.rankings.some(ranking =>
                    skiDetails[ranking.skiId]?.serialNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    skiDetails[ranking.skiId]?.grind?.toLowerCase().includes(searchTerm.toLowerCase())  
                )
            );
        })
        .sort((a, b) => {
            const dateA = new Date(a.timestamp);
            const dateB = new Date(b.timestamp);
            return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
        });
    

        setResultsToShow(filteredSortedResults);
    }, [results, searchTerm, sortOrder, skiDetails]);


    const handleEdit = (resultId) => {
        const resultToEdit = results.find(result => result.id === resultId);
        if (!resultToEdit) return;
        const resultDataWithDetails = {
            ...resultToEdit,
            rankings: resultToEdit.rankings.map(ranking => ({
                ...ranking,
                grind: skiDetails[ranking.skiId]?.grind || '', // Retrieve grind from skiDetails or set default value
                serialNumber: skiDetails[ranking.skiId]?.serialNumber || '' // Retrieve serialNumber from skiDetails or set default value
            }))
        };
        navigate(`/edit-result/${resultId}`, { state: { resultId, resultData: resultDataWithDetails } });
    };


    const handleDelete = async (resultId) => {
        if (window.confirm("Are you sure you want to delete this result?")) {
            try {
                await deleteResult(user.uid, resultId);
                const updatedResults = resultsToShow.filter(result => result.id !== resultId);
                setResultsToShow(updatedResults);
            } catch (error) {
                console.error("Error deleting tournament result:", error);
            }
        }
    };

    const highlightSearchTerm = (text) => {
        if (!searchTerm.trim()) return text;
        const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
        return parts.map((part, index) =>
            part.toLowerCase() === searchTerm.toLowerCase() ? <mark key={index}>{part}</mark> : part
        );
    };


    if (loading) return <Spinner />;
    if (error) return <div>Error loading results: {error.message}</div>;

    return (
        <HelmetProvider>
            <Helmet>
                <title>Ski-Lab: Results</title>
            </Helmet>
            <div className='py-4 px-2'>
                <div className='flex items-end justify-between'>
                    <ResultsSearch onSearchChange={setSearchTerm} />
                    <DateSort onSortChange={setSortOrder} />
                </div>
                {resultsToShow.length > 0 ? (
                    resultsToShow.map(result => (
                        <div key={result.id} className='bg-container shadow rounded-xl my-5 p-5 animate-fade-down animate-duration-300'>
                            <div className='flex justify-between items-start'>
                                <div>
                                    <h3 className='font-semibold text-lg'>
                                        {highlightSearchTerm(t(result.style))} / {highlightSearchTerm(`${result.temperature}°C`)}
                                    </h3>
                                    <i className='text-sm'>{highlightSearchTerm(result.location)}</i>
                                </div>

                                <div className='flex items-center space-x-3'>
                                    <div onClick={(e) => {
                                        e.stopPropagation();
                                        handleEdit(result.id)
                                    }} className='shadow bg-sbtn text-btn hover:bg-btn hover:text-btntxt rounded-3xl p-3 cursor-pointer'
                                    >

                                        <MdEdit />
                                    </div>
                                    <div onClick={(e) => {
                                        e.stopPropagation();
                                        handleDelete(result.id);
                                    }} className='shadow text-delete bg-sbtn hover:bg-delete hover:text-white rounded-3xl p-3 cursor-pointer'>
                                        <MdDelete />
                                    </div>
                                </div>
                            </div>
                            <ul className='my-2 p-2 space-y-2'>
                                {result.rankings.map((ranking, index) => (
                                    <li key={index} className='flex py-1'>
                                        <span className='w-1/3'>{highlightSearchTerm(skiDetails[ranking.skiId] ? skiDetails[ranking.skiId].serialNumber : 'Deleted')}</span>
                                        <span className='w-1/3 text-center'>{highlightSearchTerm(skiDetails[ranking.skiId] ? skiDetails[ranking.skiId].grind : '')}</span>
                                        <span className='w-1/3 text-end'>{ranking.score}</span>
                                    </li>
                                ))}
                            </ul>

                            <div className='my-4'>
                                <h3 className='font-semibold text-dominant mb-2'>{t('details')}</h3>
                                <ul className='text-sm grid grid-cols-2 gap-2'>
                                    <li>{t('snow_type')}: {highlightSearchTerm(t(result.snowType))}</li>
                                    <li>{t('snow_temperature')}: {result.snowTemperature}</li>
                                    <li>{t('humidity')}: {result.humidity}</li>
                                    <li>{t('comment')}: {result.comment}</li>
                                </ul>
                            </div>

                            <div className='flex justify-end mt-2'>
                                <div className='flex items-center'>
                                    <span className='px-2 border-r'>
                                        {/* Convert timestamp to Date object if needed */}
                                        {new Date(result.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                    </span>
                                    <span className='px-2'>
                                        {/* Convert timestamp to Date object if needed */}
                                        {new Date(result.timestamp).toLocaleDateString()}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className='my-4'>
                        <div className='italic'>{t('no_test_results_available')}.</div>
                    </div>
                )}
            </div>
        </HelmetProvider>
    );
};

export default ResultsPage;

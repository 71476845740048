import { useState, useEffect } from 'react';
import {
  collection,
  onSnapshot,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  query,
  orderBy,
  where,
  getCountFromServer,
  getDoc // Add this import
} from 'firebase/firestore';
import { db } from '../firebase/config';
import { useAuth } from '../context/AuthContext';

export const useSkis = () => {
    const [skis, setSkis] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user, userData } = useAuth();

    useEffect(() => {
        if (!user) {
            setSkis([]);
            setLoading(false);
            return;
        }

        setLoading(true);
        const skiCollectionRef = collection(db, `users/${user.uid}/skis`);

        // Construct the query based on user's subscription status
        let skisQuery;
        if (userData?.isPro) {
            // Pro users can see all skis
            skisQuery = query(skiCollectionRef, orderBy("serialNumber"));
        } else {
            // Free users can see only unlocked skis
            skisQuery = query(skiCollectionRef, where("locked", "==", false), orderBy("serialNumber"));
        }

        const unsubscribe = onSnapshot(skisQuery, (snapshot) => {
            const fetchedSkis = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setSkis(fetchedSkis);
            setLoading(false);
        }, (err) => {
            setError(err);
            setLoading(false);
        });

        // Cleanup listener on unmount
        return () => unsubscribe();
    }, [user, userData?.isPro]);

    const addSki = async (skiData) => {
        if (!user) return;
        try {
            // Optionally enforce a max total skis limit
            const maxTotalSkis = userData?.isPro ? 48 : 12;
            const totalSkis = (userData?.skiCount || 0) + (userData?.lockedSkisCount || 0);
            
            if (totalSkis >= maxTotalSkis) {
                alert(`You have reached the maximum total number of skis allowed (${maxTotalSkis}).`);
                return;
            }

            const newSkiData = {
                ...skiData,
                dateAdded: new Date(), // Ensure 'dateAdded' exists for ordering
            };

            await addDoc(collection(db, `users/${user.uid}/skis`), newSkiData);
        } catch (error) {
            setError(error);
        }
    };

    const updateSki = async (skiId, updatedSkiData) => {
        if (!user) return;
        try {
            // Prevent updating 'locked' field from the client
            if ('locked' in updatedSkiData) {
                delete updatedSkiData.locked;
            }
            const skiDocRef = doc(db, `users/${user.uid}/skis`, skiId);
            await updateDoc(skiDocRef, updatedSkiData);
        } catch (error) {
            setError(error);
        }
    };

    const deleteSki = async (skiId) => {
        if (!user) return;
        try {
            const skiDocRef = doc(db, `users/${user.uid}/skis`, skiId);
            await deleteDoc(skiDocRef);
        } catch (error) {
            setError(error);
        }
    };

    const updateSkisList = (newSkis) => {
        setSkis(newSkis);
    };

    return {
        skis,
        loading,
        error,
        addSki,
        updateSki,
        deleteSki,
        updateSkisList,
        totalSkis: (userData?.skiCount || 0) + (userData?.lockedSkisCount || 0),
        lockedSkisCount: userData?.lockedSkisCount || 0,
    };
};

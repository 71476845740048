// components/ManageLockedSkisPage/LockedSkiItem.jsx

import React from 'react';
import { FaTrash } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FaLock } from "react-icons/fa6";

const LockedSkiItem = ({ ski, handleDelete }) => {
  const { t } = useTranslation();

  const confirmDelete = (e) => {
    e.stopPropagation(); // Prevent triggering any parent click events
    const isConfirmed = window.confirm(
      t('are_you_sure_delete_ski', { serialNumber: ski.serialNumber })
    );
    if (isConfirmed) {
      handleDelete(ski.id);
    }
  };

  return (
    <div className="flex items-center justify-between p-3 bg-container rounded-xl">
      <div><FaLock /></div>
      <div className="flex items-center space-x-2">
        <span className="font-semibold">{t('serial_number')}: </span>
        <span>{ski.serialNumber}</span>
      </div>
      <button
        onClick={confirmDelete}
        className="text-delete hover:text-red-700 focus:outline-none"
        title={t('delete_ski')}
      >
        <FaTrash/>
      </button>
    </div>
  );
};

LockedSkiItem.propTypes = {
  ski: PropTypes.shape({
    id: PropTypes.string.isRequired,
    serialNumber: PropTypes.string.isRequired,
  }).isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default LockedSkiItem;

import React from 'react';
import { useTranslation } from 'react-i18next';

const HelpModal = ({ isOpen, onClose, help }) => {
    const { t } = useTranslation();

    if (!isOpen) return null; // Don't render the modal if it's not open

    const handleOverlayClick = () => {
        onClose();
    };

    const handleModalClick = (event) => {
        event.stopPropagation(); // Prevent click from propagating to the overlay
    };

    return (
        <div
            className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
            onClick={handleOverlayClick}
        >
            <div
                className="bg-background p-6 rounded-xl w-full mx-4 md:w-1/2 lg:w-1/3 shadow-lg"
                onClick={handleModalClick}
            >
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-semibold">Tips</h2>
                    <button
                        onClick={onClose}
                        className="text-3xl font-semibold focus:outline-none"
                        aria-label={t('close')}
                    >
                        &times;
                    </button>
                </div>
                <ul className='list-disc px-4'>
                    {help.map((tip, index) => (
                        <li key={index} className="my-2">{tip}</li>
                    ))}
                </ul>
                <button 
                    onClick={onClose} 
                    className="bg-sbtn px-5 py-3 rounded-3xl hover:opacity-90 mt-4 focus:outline-none"
                >
                    {t('close')}
                </button>
            </div>
        </div>
    );
};

export default HelpModal;

import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../components/layout/Header';
import Navigation from '../components/layout/Navigation';
import AppRoutes from './Routes';

const Layout = () => {
  const location = useLocation();

  // Check if the current path is the homepage
  const isHomePage = location.pathname === '/';

  return (
    <div className="min-h-screen md:flex bg-background text-text font-page">
      <Navigation />

      <main className={`mb-auto ${!isHomePage && 'pb-12'}  md:flex md:flex-col md:pb-0 md:w-full`}>
        {/* Conditionally render the Header only if not on the homepage */}
        {!isHomePage && <Header />}
        <div className={`${isHomePage ? 'w-full' : 'md:w-1/2 mx-auto'}`}>
          <AppRoutes />
        </div>
      </main>
    </div>
  );
};

export default Layout;

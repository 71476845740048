import React, { useContext } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { IoReorderTwo } from "react-icons/io5";
import { MdOutlineClose } from "react-icons/md";
import { MdOutlineDoubleArrow } from "react-icons/md";
import { UserPreferencesContext } from '../../../context/UserPreferencesContext';


const SkiMatchup = ({ match, scores, onScoreChange, potentialSwapTarget }) => {
    const { gloveMode } = useContext(UserPreferencesContext); // Access gloveMode

    const handleScoreChange = (skiId, e) => {
        let score;
        if (e.target.value === '') {
            // If no value is entered, set score to 0
            score = 0;
        } else {
            // Otherwise, parse the entered value
            score = parseInt(e.target.value, 10) || 0;
        }
        onScoreChange(skiId, score);
    };

    const handleFocus = (skiId, e) => {
        if (e.target.value === '0') {
            // Temporarily set value to an empty string to allow overwriting
            onScoreChange(skiId, '');
        }
    };

    const handleBlur = (skiId, e) => {
        if (e.target.value === '') {
            onScoreChange(skiId, 0);
        }
    };

    return (
        <Droppable droppableId={`match-${match.id}`}>
            {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className="match-container my-5 w-full">
                    <h3 className='font-semibold'>Test {match.id + 1}</h3>
                    {match.skis.map((ski, index) => (
                        <Draggable key={ski.id} draggableId={`ski-${match.id}-${ski.id}`} index={index}>
                            {(provided, draggableSnapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={`bg-container relative py-2 px-4 rounded-3xl flex items-center justify-evenly  my-2 ${scores[ski.id] > 0 && ('opacity-50')} ${ski.id === potentialSwapTarget?.id && (' bg-sbtn')} ${gloveMode && ('px-4 py-4 text-xl')} `}
                                >
                                    <span className={`w-1/3`}>{ski.serialNumber}</span>
                                    {ski.id === potentialSwapTarget?.id ? <p className='w-1/3 text-center'>Get swapped!</p> :
                                        <input
                                            type="number"
                                            value={scores[ski.id] !== undefined ? scores[ski.id] : 0}
                                            onChange={(e) => handleScoreChange(ski.id, e)}
                                            min="0"
                                            onFocus={(e) => handleFocus(ski.id, e)}
                                            onBlur={(e) => handleBlur(ski.id, e)}
                                            className={`text-inputtxt border flex rounded-3xl text-center p-2 w-1/3`}
                                        />}

                                    <div className='flex items-center justify-end space-x-2 w-1/3'>
                                        <div>
                                            {scores[ski.id] > 0 ? <MdOutlineClose className='text-red-500' /> : <MdOutlineDoubleArrow className='text-blue-400 ' />}

                                        </div>
                                        <div>
                                            <IoReorderTwo size={20} />
                                        </div>

                                    </div>
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
};

export default SkiMatchup;
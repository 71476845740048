// PerformanceChart.jsx
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine,
} from 'recharts';
import { useTranslation } from 'react-i18next';

const PerformanceChart = ({
    data,
    filteredGrindHistory,
    selectedYear,
    yearMinDate,
    yearMaxDate,
    minDate,
    maxDate,
    maxRank,
    CustomTooltip,
    containerWidth,
}) => {
    const { t } = useTranslation();

    // Generate unique ticks for the XAxis
    const xTicks = useMemo(() => {
        return [...new Set([...data.map(d => d.testDate), ...filteredGrindHistory.map(d => new Date(d.grindDate).getTime())])].sort((a, b) => a - b);
    }, [data, filteredGrindHistory]);

    return (
        <div className='overflow-auto scroll-smooth rounded-xl'>
            <ResponsiveContainer width={containerWidth} height={250}>
                <LineChart
                    data={data}
                    margin={{ top: 50, right: 50, left: 20, bottom: 20 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="testDate"
                        type="number"
                        domain={[selectedYear ? yearMinDate : minDate, selectedYear ? yearMaxDate : maxDate]}
                        tickFormatter={(tick) => new Date(tick).toLocaleDateString()}
                        ticks={xTicks}
                        label={{ value: t('test_date'), position: 'insideBottom', offset: -30, fontSize: 14, fill: 'var(--color-text)'}}
                        stroke='var(--color-text)'
                        tickMargin={10}
                    />

                    <YAxis
                        type="number"
                        domain={[maxRank, 1]} // Inverted domain for rank
                        allowDecimals={false}
                        label={{ value: t('rank'), angle: -90, position: 'insideLeft', fontSize: 14, fill: 'var(--color-text)' }}
                        stroke='var(--color-text)'
                    />
                    <Tooltip content={CustomTooltip} />
                    <Legend align="left" verticalAlign="bottom"/>
                    <Line
                        type="monotone"
                        dataKey="rank"
                        name={t('rank')}
                        stroke="var(--color-line)"
                        strokeWidth={2}
                        activeDot={{ r: 8 }}
                    />
                    {filteredGrindHistory.map((data, index) => (
                        <ReferenceLine
                            key={`grind-${index}`}
                            x={new Date(data.grindDate).getTime()}
                            stroke='var(--color-reference)'
                            strokeWidth={1}
                            strokeDasharray="3 3"
                            label={{
                                value: `${data.grind}`,
                                position: 'top',
                                offset: 15,
                                fontSize: 12,
                                fill: "var(--color-text)",
                            }}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default React.memo(PerformanceChart);

// components/ManageLockedSkisPage.jsx

import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LockedSkiItem from './LockedSkiItem'; // Adjust the path accordingly
import { SiRundeck } from "react-icons/si";
import { useAuth } from '../../context/AuthContext';
import { useLockedSkis } from '../../hooks/useLockedSkis';
import Spinner from '../../components/common/Spinner';
import BackBtn from '../../components/common/BackBtn';
import GetPro from '../../components/getPro/GetPro';
import { FaLockOpen } from "react-icons/fa6";



const ManageLockedSkisPage = () => {
  const { lockedSkis, loading, error, deleteLockedSki } = useLockedSkis();
  const { userData } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleDelete = async (skiId) => {
    try {
      await deleteLockedSki(skiId);
      alert(t('ski_deleted_successfully'));
    } catch (err) {
      console.error(err);
      alert(t('error_deleting_ski'));
    }
  };

  if (loading) return <Spinner />;
  if (error) return <div className='m-2 text-red-500'>Error: {error.message}</div>;

  return (
    <HelmetProvider>
      <Helmet>
        <title>Ski-Lab: {t('manage_locked_skis')}</title>
      </Helmet>
      <div className="manage-locked-skis-page py-4 px-2 max-w-3xl mx-auto">
        <div className="flex flex-col items-center mb-6">
          <h1 className="flex text-3xl font-semibold mb-2">{t('manage_locked_skis')}</h1>
          <div className="flex text-center">{t('delete')} {t('or')} <button className='ml-1 text-btn underline flex'><GetPro /><FaLockOpen className='ml-1' /></button></div>
        </div>

        {lockedSkis.length === 0 ? (
          <div className='text-center text-gray-500'>
            {t('no_locked_skis')}

          </div>
        ) : (
          <div className="space-y-2">
            {lockedSkis.map(ski => (
              <LockedSkiItem
                key={ski.id}
                ski={ski}
                handleDelete={handleDelete}
              />
            ))}
          </div>
        )}

        <div className="mt-8 flex justify-center">
          <BackBtn />
        </div>
      </div>
    </HelmetProvider>
  );
};

export default ManageLockedSkisPage;
import React, { useState, useContext } from 'react';
import { MdArrowDropDown, MdArrowDropUp, MdHistory } from "react-icons/md"; // Import MdNewReleases
import SkiDetail from '../SkiDetail/SkiDetail';
import { useTranslation } from 'react-i18next';
import { UserPreferencesContext } from '../../../context/UserPreferencesContext'; // Adjust the import path accordingly

const SkiItem = ({
  ski,
  handleCheckboxChange,
  handleEdit,
  handleArchive, // Add this handler
  handleDelete, // Existing delete handler
  handleUnarchive,
  selectedSkis,
  expandedSkiId,
  toggleDetails
}) => {
  const { t } = useTranslation();
  const showDetails = ski.id === expandedSkiId;
  const { gloveMode } = useContext(UserPreferencesContext); // Access gloveMode

  // Helper function to determine if the ski is new (added within the last year)
  const isNew = () => {
    if (!ski.dateAdded) return false; // If dateAdded is not available, return false
    const twoMonthAgo = new Date();
    twoMonthAgo.setMonth(twoMonthAgo.getMonth() - 2);
    const dateAdded = new Date(ski.dateAdded);
    return dateAdded >= twoMonthAgo;
  };

  const handleEditClick = async () => {
    await handleEdit(ski);
  }

  const handleArchiveClick = async () => {
    await handleArchive(ski.id);
  };

  const handleDeleteFinalClick = async () => {
    await handleDelete(ski.id); // Perform final deletion
  };

  const handleUnarchiveClick = async () => {
    await handleUnarchive(ski.id); // Unarchive the ski
  };

  return (
    <div className={`shadow  ${!showDetails && 'hover:bg-sbtn shadow-none'} ${!gloveMode && 'bg-container rounded-xl'} ${selectedSkis[ski.id] && 'bg-selected rounded-xl'} animate-fade-down animate-duration-300`}>
      {/* Main ski item row */}
      <div
        className={`p-3 cursor-pointer flex items-center  ${gloveMode && 'py-2'}`}
        onClick={() => toggleDetails(ski.id)}
      >
        <div className={`flex items-center space-x-1 ${showDetails && 'font-semibold'}`}>
          <input
            type="checkbox"
            checked={selectedSkis[ski.id] || false}
            onChange={() => handleCheckboxChange(ski.id)}
            className={`mr-2 accent-btn ${gloveMode ? 'w-10 h-10' : 'w-4 h-4'}`}
            onClick={(e) => e.stopPropagation()}
          />

          {!gloveMode ? (
            <>
              <span >{ski.serialNumber}</span>
              <span>/</span>
              <span>{ski.grind}</span>
              <span>/</span>
              <span>{t(ski.style)}</span>
            </>
          ) :
            <div className='flex flex-col'>
              <span className={`text-lg font-bold`}>{ski.serialNumber}</span>
              <span>{t(ski.style)}</span>
            </div>
          }
          {!gloveMode && (
            <p className='mx-1'>
              {showDetails ? <MdArrowDropUp size={20} /> : <MdArrowDropDown size={20} />}
            </p>
          )}

          {ski.archived && <MdHistory />}

          {isNew() && !gloveMode && (
            <p className='text-btn text-xs'>{t('new')}</p>
          )}
        </div>

        {!gloveMode && showDetails && (
          <div className='flex items-center flex-1 justify-end space-x-2'>



          </div>
        )}
      </div>

      {/* Ski details */}
      {showDetails && !gloveMode && (
        <div className='mt-2'>
          <SkiDetail
            ski={ski}
            onEdit={handleEditClick}
            onArchive={handleArchiveClick}
            onUnarchive={handleUnarchiveClick}
            onDelete={handleDeleteFinalClick}
          />
        </div>
      )}
    </div>
  );
};

export default SkiItem;

// PasswordReset.js
import React, { useState } from 'react';
import { sendPasswordReset } from '../../firebase/authFunctions';
import { useNavigate } from 'react-router-dom';

const PasswordReset = () => {
  const [resetEmail, setResetEmail] = useState('');
  const [resetError, setResetError] = useState(null);
  const [isResetting, setIsResetting] = useState(false);

  const navigate = useNavigate();

  const handleResetPassword = async () => {
    try {
      setIsResetting(true);
      setResetError('');

      // Add your password reset function from Firebase
      await sendPasswordReset(resetEmail);

      console.log('Password reset email sent. Check your inbox.');
    } catch (error) {
      // Handle errors during the password reset process
      switch (error.code) {
        case 'auth/invalid-email':
          setResetError('Invalid email');
          break;

        default:
          setResetError('Error sending password reset email. Please try again.');
          break;
      }
    } finally {
      setIsResetting(false);
    }
  };

  return (
    <div className="m-auto mt-32 w-2/3 md:w-1/2 animate-fade-down animate-duration-300">
      <h1 className='text-dominant text-4xl mb-10 font-semibold'>Reset password</h1>
      <div className="flex flex-col space-y-2 text-black">
        <input
          type="email"
          value={resetEmail}
          onChange={(e) => setResetEmail(e.target.value)}
          placeholder="Email"
          className="w-full px-4 py-2 border border-gray-300 rounded-3xl"
          required
        />
        <button
          type="button"
          onClick={handleResetPassword}
          className="bg-btn hover:opacity-90 text-btntxt my-2 px-4 py-2  rounded-3xl"
          disabled={isResetting}
        >
          {isResetting ? 'Sending...' : 'Reset Password'}
        </button>
        <button className='px-4 py-2 bg-sbtn text-text rounded-3xl' onClick={() => navigate('/signin')}>Back</button>
        {resetError && (
          <div className="text-red-500 font-thin">{resetError}</div>
        )}
      </div>

    </div>
  );
};

export default PasswordReset;

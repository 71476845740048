// components/SkisPage.jsx
import React, { useContext, useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSkis } from '../../hooks/useSkis';
import SkiItem from './SkiItem/SkiItem';
import Spinner from '../../components/common/Spinner';
import { SiRundeck } from "react-icons/si";
import SkiFilter from './SkiFilter/SkiFilter';
import SkiSort from './SkiSort/SkiSort';
import { UserPreferencesContext } from '../../context/UserPreferencesContext';
import { useAuth } from '../../context/AuthContext';
import GetPro from '../../components/getPro/GetPro';
import { FaLock, FaLockOpen } from "react-icons/fa6";


const SkisPage = () => {
  const { skis, loading, error, updateSkisList, deleteSki, updateSki, totalSkis, lockedSkisCount } = useSkis();
  const { gloveMode } = useContext(UserPreferencesContext);
  const { userData } = useAuth();
  const [selectedSkis, setSelectedSkis] = useState({});
  const [selectedSkisForTournament, setSelectedSkisForTournament] = useState([]);
  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState('serialNumber');
  const [expandedSkiId, setExpandedSkiId] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedSkisForTournament(getSelectedSkis());
  }, [selectedSkis, skis]);

  const toggleDetails = (skiId) => {
    setExpandedSkiId(expandedSkiId === skiId ? null : skiId);
  };

  const getSelectedSkis = () =>
    Object.entries(selectedSkis)
      .filter(([_, isSelected]) => isSelected)
      .map(([id]) => skis.find(ski => ski.id === id))
      .filter(ski => ski); // Ensure no undefined

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const handleSortChange = (sortField) => {
    setSort(sortField);
    const sortedSkis = [...skis].sort((a, b) =>
      a[sortField] < b[sortField] ? -1 : a[sortField] > b[sortField] ? 1 : 0
    );
    updateSkisList(sortedSkis);
  };

  const handleArchive = async (skiId) => {
    const skiToUpdate = skis.find(ski => ski.id === skiId);
    if (skiToUpdate) {
      await updateSki(skiId, { archived: true });
    }
  };

  const handleUnarchive = async (skiId) => {
    const skiToUpdate = skis.find(ski => ski.id === skiId);
    if (skiToUpdate) {
      await updateSki(skiId, { archived: false });
    }
  };

  const handleAddSki = () => {
    navigate('/add-skis');
  };

  const handleEdit = (ski) => {
    navigate('/add-skis', { state: { ski, isEdit: true } });
  };

  const handleDelete = async (skiId) => {
    if (window.confirm("Are you sure you want to delete this ski?")) {
      if (window.confirm("This will also remove the ski from results? You can't undo this")) {
        await deleteSki(skiId);
      }
    }
  };

  const handleCheckboxChange = (skiId) => {
    setSelectedSkis((prev) => ({ ...prev, [skiId]: !prev[skiId] }));
  };

  const handleStartTournament = () => {
    if (selectedSkisForTournament.length > 1) {
      navigate('/testing', { state: { selectedSkis: selectedSkisForTournament } });
    } else {
      alert("Please select more ski pairs");
    }
  };

  // Determine ski limits
  const skiCount = userData?.skiCount || 0; // Unlocked skis
  const skiLimit = userData?.isPro ? 48 : 12;
  const hasReachedLimit = skiCount >= skiLimit;
  const hasLockedSkis = lockedSkisCount > 0;

  if (loading) return <Spinner />;
  if (error) return <div className='m-2'>Error: {error.message}</div>;

  const filteredSkis = [...new Set(skis
    .filter(ski => {
      if (filter === 'all_with_archive') return true;
      if (filter === 'archived') return ski.archived;
      if (!filter) return !ski.archived;
      return ski.style.toLowerCase() === filter && !ski.archived;
    })
    .map(ski => ski.id))].map(id => skis.find(ski => ski.id === id));

  // Sort filtered skis
  const sortedAndFilteredSkis = filteredSkis.sort((a, b) =>
    a[sort] < b[sort] ? -1 : a[sort] > b[sort] ? 1 : 0
  );

  return (
    <HelmetProvider>
      <Helmet>
        <title>Ski-Lab: Skis</title>
      </Helmet>
      <div className="skis-page py-2 px-2">

        <div className="flex flex-col md:flex-row md:justify-between mb-4">

          <div className={`grid gap-5 px-2 ${gloveMode ? 'grid-cols-1' : 'grid-cols-2'}`}>
            <div className='flex flex-col items-center justify-end'>
              <h3 className="text-sm font-semibold mb-1">
                {selectedSkisForTournament.length} {t('skipairs_selected')}
              </h3>
              <button
                onClick={handleStartTournament}
                className={`flex h-fit items-center justify-center bg-btn text-btntxt shadow py-3 px-5 text-center rounded-3xl w-full max-w-xs ${selectedSkisForTournament.length < 2 ? "opacity-50 cursor-default" : "hover:opacity-90"
                  }`}
              >
                {t('start_test')}
                <SiRundeck className="ml-2" />
              </button>
            </div>
            {!gloveMode && (
              <div className='flex flex-col items-center justify-end'>
                <h3 className={`flex items-center text-sm font-semibold mb-1 ${skiCount >= skiLimit && 'text-delete'}`}>
                  <p>{skiCount}</p> / <p>{skiLimit}</p>  {hasReachedLimit && <p className='ml-1'>- Max</p>} {!userData?.isPro && !hasReachedLimit && <div className='flex text-btn text-base'><p className='mx-1'>-</p><GetPro /> <FaLockOpen className='ml-1' /></div>}

                </h3>

                {!userData?.isPro && hasReachedLimit ? <div className='flex h-fit self-end justify-center bg-btn text-btntxt shadow py-3 px-5 text-center rounded-3xl w-full max-w-xs space-x-1'><GetPro /> <FaLockOpen /></div> :
                  <button
                    onClick={handleAddSki}
                    disabled={hasReachedLimit}
                    className={`flex h-fit self-end items-center justify-center bg-btn text-btntxt shadow py-3 px-5 text-center rounded-3xl w-full max-w-xs ${hasReachedLimit ? "opacity-50 cursor-not-allowed" : "hover:opacity-90"}`}
                    title={hasReachedLimit ? `You have reached the maximum number of skis (${skiLimit}) for your plan.` : ''}
                  >
                    {t('add_ski_pair')}
                  </button>
                }

              </div>
            )}
          </div>

          {!gloveMode && (
            <div className="flex justify-between items-end md:space-x-4 px-2 mt-2">
              <SkiSort onSortChange={handleSortChange} currentSort={sort} />
              <SkiFilter onFilterChange={handleFilterChange} currentFilter={filter} />
            </div>
          )}
        </div>

        {hasLockedSkis && !userData.isPro && (
          <div className="flex flex-col items-center bg-container p-3 rounded-lg shadow animate-shake space-y-2 md:flex-row md:justify-between md:space-y-0 mx-2">
            <div className="flex items-center space-x-2">
              <FaLock className="text-gray-600" />
              <span className="text-sm font-semibold">{lockedSkisCount} locked ski(s)</span>
            </div>
            <div className="flex flex-col w-full space-y-2 md:flex-row md:space-y-0 md:space-x-4 md:w-1/2">
              <button
                onClick={() => navigate('/manage-locked-skis')}
                className="bg-btn text-btntxt w-full px-3 py-2 rounded-lg hover:opacity-90 "
              >
                Manage Locked Skis
              </button>
              <div
                className="flex items-center justify-center bg-btn text-btntxt w-full px-3 py-2 rounded-lg hover:opacity-90"
              >
                <GetPro />
                <FaLockOpen className='ml-1'/>
              </div>
            </div>
          </div>
        )}



        {sortedAndFilteredSkis.length === 0 && (
          <div className='my-4'>
            <div className='italic'>{t('you_have_no')} {filter} {t('skis')}.</div>
          </div>
        )}
        <div className={`flex flex-col ${gloveMode ? 'grid grid-cols-2 gap-4' : 'space-y-2'} m-2`}>
          {sortedAndFilteredSkis.map(ski => (
            <SkiItem
              key={ski.id}
              ski={ski}
              handleCheckboxChange={handleCheckboxChange}
              handleEdit={() => handleEdit(ski)}
              handleDelete={handleDelete}
              handleArchive={handleArchive}
              handleUnarchive={handleUnarchive}
              selectedSkis={selectedSkis}
              expandedSkiId={expandedSkiId}
              toggleDetails={toggleDetails}
            />
          ))}
        </div>
      </div>
    </HelmetProvider>
  );
};

export default SkisPage;